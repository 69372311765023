import React from 'react';
import { Typography } from '@heycater/design-system';
import { useRouter } from 'next/router';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import EventmanagementImg from '@images/homepage/eventmanagement.png';
import IndividualOfferImg from '@images/homepage/individualoffer.png';
import PrivatemarketplaceImg from '@images/homepage/privatemarketplace.png';
import { ABLyft, CUSTOM_ABLYFT_EVENT } from 'lib/ablyft';
import {
  CardsArray,
  CardsSlider,
} from 'static_pages/home/variationB/components/CardsSlider';

export default function SpecificNeedsCarousel() {
  const { t } = useTranslation('homepage');
  const router = useRouter();
  const openCalendlyLink = (link: string) => {
    ABLyft.trackCustomEvent(CUSTOM_ABLYFT_EVENT.calendlyEngagement);
    window.open(link, '_blank');
  };

  const scrollToQF = (link: string) => {
    router.push(link);
  };

  const cards: CardsArray = [
    {
      title: t('specificNeeds.card1.title'),
      content: t('specificNeeds.card1.content'),
      image: IndividualOfferImg,
      chips: [
        t('specificNeeds.card1.chips.1'),
        t('specificNeeds.card1.chips.2'),
      ],
      cta: {
        title: t('specificNeeds.card1.cta'),
        variant: 'primary',
        onClick: scrollToQF,
        link: '#qf-start',
      },
    },
    {
      title: t('specificNeeds.card2.title'),
      content: t('specificNeeds.card2.content'),
      image: EventmanagementImg,
      chips: [
        t('specificNeeds.card2.chips.1'),
        t('specificNeeds.card2.chips.2'),
      ],
      cta: {
        title: t('specificNeeds.card2.cta'),
        variant: 'secondary',
        onClick: openCalendlyLink,
        link: 'https://calendly.com/anna-marie-stiddig/30min',
      },
    },
    {
      title: t('specificNeeds.card3.title'),
      content: t('specificNeeds.card3.content'),
      image: PrivatemarketplaceImg,
      chips: [
        t('specificNeeds.card3.chips.1'),
        t('specificNeeds.card3.chips.2'),
      ],
      cta: {
        title: t('specificNeeds.card3.cta'),
        variant: 'secondary',
        onClick: openCalendlyLink,
        link: 'https://calendly.com/fee-heycater-meetme/15min',
      },
    },
  ];

  return (
    <Wrapper>
      <Title>{t('products.specificNeeds')}</Title>
      <CardsSlider cards={cards} />
    </Wrapper>
  );
}

const Title = styled(Typography)`
  font-weight: 700;
  font-size: 24px;
  line-height: 33.6px;
  text-align: left;
  ${({ theme }) => theme.breakpoints.up('md')} {
    font-size: 32px;
    text-align: center;
    line-height: 48px;
  }
`;

const Wrapper = styled.div`
  background: #f8f8f8;
  width: 343px;
  padding: ${({ theme }) => theme.spacing(3)}px;
  padding-bottom: 54px;
  border-radius: ${({ theme }) => theme.spacing(2)}px;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(4)}px;
  .slick-arrow {
    color: #3c4340;
  }
  margin: auto;
  ${({ theme }) => theme.breakpoints.up('md')} {
    width: 540px;
  }
`;
