import React from 'react';
import styled from 'styled-components';

import RegularNeedsCarousel from 'static_pages/home/variationB/components/RegularNeedsCarousel';
import SpecificNeedsCarousel from 'static_pages/home/variationB/components/SpecificNeedsCarousel';

export default function CardSelections() {
  return (
    <Wrapper>
      <SpecificNeedsCarousel />
      <RegularNeedsCarousel />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  margin: auto;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(3)}px;

  ${({ theme }) => theme.breakpoints.up('md')} {
    width: 1118px;
    flex-direction: row;
    padding: ${({ theme }) => theme.spacing(0, 2)};
  }
`;
