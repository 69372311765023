import React, { useState } from 'react';
import { Box, Button, Typography } from '@heycater/design-system';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import Image from 'next/image';
import { StaticImageData } from 'next/image';
import Slider from 'react-slick';
import styled from 'styled-components';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { useBreakpointDown } from 'qualification/hooks/useBreakpoints';

type StringInputFunction = (input: string) => void;

interface Cta {
  title: string;
  variant: 'secondary' | 'primary'; // You can add more variants if needed
  link: string;
  onClick: StringInputFunction;
}

interface Card {
  title: string;
  content: string;
  image: StaticImageData | string; // If image is React component (SVG) or a URL string
  chips: string[]; // An array of strings
  cta: Cta;
}

export type CardsArray = Card[];
export function CardsSlider({ cards = [] }: { cards: CardsArray }) {
  const [sliderRef, setSliderRef] = useState<Slider | null>(null);
  const [currentTab, setCurrentTab] = useState(0);
  const mobile = useBreakpointDown('sm');
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    beforeChange: (oldIndex: any, newIndex: any) => setCurrentTab(newIndex),
    prevArrow: <BackIcon />,
    nextArrow: <NextIcon />,
    arrows: !mobile,
    appendDots: (dots: any) => (
      <div style={{ bottom: '-40px' }}>
        <ul style={{ margin: '0px', padding: 0 }}> {dots} </ul>
      </div>
    ),
    customPaging: (i: any) => (
      <div className={`custom-dot ${currentTab === i ? 'active' : ''}`}>
        <IndicatorIcon
          style={{
            fill: currentTab === i ? '#3C4340' : '#E3E4E4',
          }}
        />
      </div>
    ),
  };

  const handleTabClick = (index: any) => {
    sliderRef?.slickGoTo(index);
    setCurrentTab(index);
  };

  return (
    <>
      <ChipsWrapper justifyContent="center">
        {cards.map((card, index) => (
          <SelectorChip
            key={index}
            style={{
              background: currentTab === index ? '#686D6B' : '#E3E4E4',
              color: currentTab === index ? '#FFFFFF' : '#3C4340',
            }}
            className={`tab ${currentTab === index ? 'active' : ''}`}
            onClick={() => handleTabClick(index)}
          >
            {card.title}
          </SelectorChip>
        ))}
      </ChipsWrapper>
      <SliderWrapper>
        <Slider ref={(slider) => setSliderRef(slider)} {...settings}>
          {cards.map((card, index) => (
            <Card key={index}>
              <Container>
                <div>
                  <Image
                    priority
                    src={card.image}
                    height={176}
                    width={261}
                    quality={100}
                    alt={card.title}
                  />
                  <Title>{card.title}</Title>
                  <Subtitle>{card.content}</Subtitle>
                  <ChipsWrapper>
                    {card.chips.map((chip, index) => (
                      <Chip key={`chip-${index}`}>{chip}</Chip>
                    ))}
                  </ChipsWrapper>
                </div>
                <div>
                  <StyledButton
                    onClick={() => card.cta.onClick(card.cta.link)}
                    variant={card.cta.variant}
                  >
                    {card.cta.title}
                  </StyledButton>
                </div>
              </Container>
            </Card>
          ))}
        </Slider>
      </SliderWrapper>
    </>
  );
}

const SelectorChip = styled.button`
  border-radius: 33px;
  padding: ${({ theme }) => theme.spacing(0.5, 2)};
  font-size: 10px;
  border: none;
  cursor: pointer;
  white-space: nowrap;
`;

const Title = styled(Typography)`
  font-size: 16px;
  font-weight: 700;
  line-height: 26.88px;
  margin-top: ${({ theme }) => theme.spacing(4)}px;
`;

const Subtitle = styled(Typography)`
  font-size: 10px;
  line-height: 17px;
  letter-spacing: 0.4px;
  margin: ${({ theme }) => theme.spacing(2, 0)};
`;

const StyledButton = styled(Button)`
  border-radius: 47px;
  padding: ${({ theme }) => theme.spacing(1, 3)};
  width: fill-available;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

const ChipsWrapper = styled(Box)`
  display: flex;
  gap: ${({ theme }) => theme.spacing(1)}px;
  flex-wrap: wrap;
`;

const Chip = styled(Box)`
  background: #f0f0f0;
  border-radius: 33px;
  padding: ${({ theme }) => theme.spacing(0.5)}px;
  white-space: nowrap;
  font-size: 8px;
  letter-spacing: 0.4px;
`;

const IndicatorIcon = styled(FiberManualRecordIcon)`
  font-size: 11px;
  &:hover {
    fill: #3c4340;
  }
`;

const Card = styled(Box)`
  display: block !important;
  margin: auto;
  width: 293px !important;
  height: 472px !important;
  padding: ${({ theme }) => theme.spacing(2)}px;
  gap: 32px;
  border-radius: 20px;
  background: #ffffff;
`;

const BackIcon = styled(ArrowBackIosIcon)`
  font-size: 32px !important;
  height: 32px !important;
  width: 32px !important;
  color: #e3e4e4 !important;
  &:hover {
    color: #3c4340 !important;
  }
`;

const NextIcon = styled(ArrowBackIosIcon)`
  font-size: 32px !important;
  transform: translateY(-50%) rotate(180deg) !important;
  height: 32px !important;
  width: 32px !important;
  color: #3c4340 !important;
`;

const SliderWrapper = styled(Box)`
  padding: 0;
  ${({ theme }) => theme.breakpoints.up('md')} {
    padding: ${({ theme }) => theme.spacing(0, 3)};
  }
`;
